@import "../../../../../../../shared/styles/media";
@import "../../../../../../../shared/styles/typography";

.address_sub_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
  background: #f1f0f5;
  margin-top: 20px;

  .title {
    @extend %big_txt;
  }

  .description {
    display: flex;
    align-items: center;
    gap: 5px;

    @extend %paragraph_16_med;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  .button {
    background: transparent;
    border-color: transparent;
    align-items: center;
    &:hover {
      border-color: transparent;
    }
  }

  @include md {
    text-align: left;
    padding: 25px 15px 15px 15px;
    margin: 0;

    align-items: flex-start;

    .button {
      text-align: left;
      width: auto;
      padding-left: 0;
    }
  }
}
