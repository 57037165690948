@import "../../../../../../../shared/styles/media";
@import "../../../../../../../shared/styles/typography";

.reviews_channel_sub_section {
  background: var(--white);
  margin: 0 var(--container-horizontal-padding-negative);
  padding: 52px var(--container-horizontal-padding) 63px;

  .container {
    display: flex;
    gap: 30px;
    width: 100%;
    max-width: 1208px;
    margin: 0 auto;

    @include md {
      flex-wrap: wrap;
    }
  }

  .image {
    width: calc(50% - 60px);
    height: auto;
    margin-left: 60px;
    object-fit: cover;

    @include md {
      width: 100%;
      margin-left: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 30px;

    .title {
      @extend %big_txt;
    }

    .description {
      color: var(--medium-gray);
      margin-bottom: 35px;
      margin-top: 15px;

      @extend %paragraph_16_reg;
    }

    .button {
      display: flex;
      svg {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
      }
    }

    @include md {
      padding: 0;
      a {
        width: 100%;
      }
      .button {
        padding: 18px 18px;
        width: 100%;
      }
    }
  }
}
