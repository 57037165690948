@import "shared/styles/typography.scss";

.a {
  color: var(--ui-main-black-lite);

  height: 40px;
  display: flex;
  align-items: center;

  @extend %paragraph_medium;

  @media (hover: none) {
    &:hover {
      color: inherit;
    }
  }
}
