@import "../../../../../shared/styles/media";

.guarantee_section {
  padding: 100px var(--container-horizontal-padding);

  background: var(--black);
  color: var(--white);
  .container {
    max-width: var(--container-width);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 108px;
  }

  @include sm {
    padding: 80px var(--container-horizontal-padding);

    .container {
      gap: 80px;
    }
  }
}
