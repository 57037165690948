@import "../../../../../../../shared/styles/media";

.reviews_sub_section {
  max-width: 1208px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 62px;

  @include sm {
    gap: 20px;
  }

  .reviews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    max-height: 75vh;
    overflow: hidden;

    .column_reviews {
      width: calc(100% / 4);
      display: flex;
      flex-direction: column;
      gap: 11px;

      &:nth-child(1) {
        margin-top: 5%;
      }

      &:nth-child(3) {
        margin-top: 7%;
      }

      &:nth-child(4) {
        margin-top: 2%;
      }

      .image {
        width: 100%;
        height: auto;
      }
    }

    @include md {
      display: none;
    }
  }

  .scrolling_reviews {
    display: none;
  }

  @include md {
    .scrolling_reviews {
      display: flex;
      align-items: flex-start;
      gap: 0px;
      flex-wrap: nowrap;
      scroll-snap-type: x mandatory;
      justify-content: flex-start;
      scroll-padding-left: var(--container-horizontal-padding);
      padding: 0 var(--container-horizontal-padding);

      margin: 0 var(--container-horizontal-padding-negative);
      width: calc(100% + var(--container-horizontal-padding) * 2);
      align-self: flex-start;
      overflow-x: auto;
      margin-bottom: 33px;

      &::-webkit-scrollbar {
        display: none;
      }

      .image {
        width: calc(100% / 3.2);
        min-width: 250px;
        height: auto;
      }
    }
  }
}
