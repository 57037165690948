@import "shared/styles/media";

.wrapper {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  overflow: hidden;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: 0;
}

.content {
  width: 100%;
  max-width: 776px;
  margin: 110px auto 0;
  position: relative;
  z-index: 9;
  @include md {
    max-width: 100%;
    margin: 0;
    height: 100vh;
    background: #ffffff;
  }
}

.searchInputContainer {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  gap: 10px;
  padding: 5px 5px 5px 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #fff;
  @include md {
    height: 60px;
    background: none;
    border-radius: 0;
    padding: 12px 15px 23px 15px;
  }
}

.searchInput {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  font-family: var(--font);
  outline: none;
  border: none;
  color: #000000;
  background: none;
  position: relative;
  z-index: 1;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::placeholder {
    color: #c5c5c5;
  }
}

.searchInputActions {
  width: 100%;
  height: 60px;
  padding: 12px 15px 23px 15px;
  position: absolute;
  top: 0;
  z-index: 0;

  display: flex;
  justify-content: space-between;
  cursor: text;
}

.actionButton {
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.actionsGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.scrollListMobile {
  display: none;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  @include md {
    display: flex;
  }
}

.searchHistory {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 15px;

  .searchHistoryTitle {
    font-family: var(--font);
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    color: #000000;
  }

  .searchHistoryList {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  button {
    padding: 5px 13px;
    width: fit-content;

    display: flex;
    align-items: center;
    gap: 3px;

    font-family: var(--font);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;

    border: none;
    outline: none;
    color: #000000;
    cursor: pointer;
    border-radius: 9999px;
    background: #f2f2f2;
    button {
      padding: 0;
      background: none;
      border: none;
    }
  }
}

.oftenSearched {
  padding: 10px 15px 20px 15px;
  display: flex;
  flex-direction: column;

  .oftenSearchedTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    color: #000000;
  }
}

.searchItem {
  padding: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background: none;
  border: none;
  svg {
    min-width: 40px;
  }
  h2 {
    width: 100%;
    font-family: var(--font);
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    color: #000000;
    span {
      font-weight: 700;
    }
  }
  &:after {
    width: calc(100% - 50px);
    margin-left: 50px;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    background: #e0e0e0;
  }
}

.plits {
  gap: 5px;
  display: flex;
  padding: 0 15px;
  flex-wrap: wrap;
  button {
    width: fit-content;
    padding: 10px;
    display: flex;

    font-family: var(--font);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;

    outline: none;
    border: none;
    color: #000000;
    cursor: pointer;
    border-radius: 10px;
    background: #f2f2f2;
  }
}

.suggestionsList {
  display: flex;
  padding: 0 15px;
  flex-direction: column;
}

.mobileButton {
  display: none;
  @include md {
    display: flex;
  }
}

.searchButtonText {
  width: 89px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font);
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  color: #ffffff;
  border-radius: 7px;
  border: none;
  background: #000000;
  cursor: pointer;
  @include md {
    display: none;
  }
}

.searchIcon {
  width: 16.5px;
  height: 16.5px;
  svg {
    width: 16.5px;
    height: 16.5px;
  }
  @include md {
    display: none;
  }
}

.searchListDesktop {
  width: 100%;
  padding: 3px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #ffffff;
  button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    font-family: var(--font);
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    color: #000000;
    border-radius: 7px;
    cursor: pointer;
    transition: 350ms;
    background: none;
    border: none;
    span {
      font-weight: 700;
    }
    &:hover {
      background: #f2f2f2;
    }
    &[data-selected="true"] {
      background: #f2f2f2;
    }
    .main {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
  @include md {
    display: none;
  }
}

.searchHistoryDesktop {
  h2 {
    padding: 14px 0 6px 13px;
    font-family: var(--font);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    color: #8b8b8b;
  }
}

.searchPopularDesktop {
  h2 {
    padding: 14px 0 6px 13px;
    font-family: var(--font);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    color: #8b8b8b;
  }
}
