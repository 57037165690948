@import "../../../../../shared/styles/media";
@import "../../../../../shared/styles/typography";

.categories_models_section {
  background: var(--black);
  padding: 40px var(--container-horizontal-padding);

  .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr) 2fr 1fr 0.6fr repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: 130vh;

    @include md {
      grid-column-gap: 2px;
      grid-row-gap: 3px;
      grid-template-columns: 1.7fr 1fr 1.7fr;
      grid-template-rows: repeat(2, 1fr) 3fr repeat(2, 1fr);
      padding: 0;
      height: 100vh;
    }

    @include sm {
      height: 70vh;
    }
  }

  @include sm {
    padding: 0;
  }

  .card {
    display: flex;
    position: relative;
    background: var(--background);
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &:nth-child(1) {
      grid-area: 1 / 1 / 4 / 9;
    }

    &:nth-child(2) {
      grid-area: 1 / 9 / 5 / 13;
    }

    &:nth-child(3) {
      grid-area: 4 / 1 / 8 / 5;
    }

    &:nth-child(4) {
      grid-area: 4 / 5 / 8 / 9;
    }

    &:nth-child(5) {
      grid-area: 5 / 9 / 8 / 13;
    }

    &:hover {
      color: inherit;
      .image {
        transition: all 0.3s linear;
        transform: scale(1.1);
      }
    }

    .price {
      @extend %header_2_txt;
    }

    @include md {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: rgba(0, 0, 0, 0.3);
      }

      &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 3;
      }

      &:nth-child(2) {
        grid-area: 1 / 3 / 3 / 4;
      }

      &:nth-child(3) {
        grid-area: 3 / 1 / 4 / 4;
      }

      &:nth-child(4) {
        grid-area: 4 / 1 / 6 / 2;
      }

      &:nth-child(5) {
        grid-area: 4 / 2 / 6 / 4;
      }

      .price {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.36px;
        text-transform: uppercase;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      transition: all 0.3s linear;
      transform: scale(1);
    }

    .content {
      position: absolute;
      background: #fff;
      padding: 15px;
      display: flex;
      flex-direction: column;
      left: 0;
      bottom: 0;
      max-width: 270px;
      width: 100%;

      .title {
        @extend %paragraph_16_med;
      }

      .count_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: var(--accent);

        @extend %paragraph_16_med;

        .icon {
          width: 20px;
          height: 20px;
        }
      }

      @include md {
        background: transparent;
        color: var(--white);
        padding: 15px 10px;
        z-index: 1;
        width: 100%;

        .count_box {
          display: none;
        }
      }
    }
  }
}
