@import "../../../../../shared/styles/media";
.shop_section {
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 100%;
  padding: 0 var(--container-horizontal-padding);

  @include sm {
    gap: 30px;
  }
}
