.wrapper {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
  min-height: calc(100vh - 71px);
  font-family: var(--font);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .title {
      font-size: 130px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 130px */
    }

    .desc {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
  }

  .button {
    // display: inline-flex;
    // min-width: 120px;
    // padding: 15px 20px;
    // flex-direction: column;
    // align-items: flex-start;
    // gap: 10px;

    // font-size: 14px;
    // font-style: normal;
    // font-weight: 500;
    // line-height: 140%;
  }
}
