@import "../../../../../shared/styles/typography";
@import "../../../../../shared/styles/media";

.cover_section {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 129px);
  background: var(--black);

  @include sm {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
    z-index: 0;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .wrapper {
    max-width: 920px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 70px 0 0;
    min-height: calc(100vh - 129px);
    align-items: center;
    justify-content: center;
    gap: 80px;

    @include sm {
      padding: 0;
      position: static;
    }

    .content {
      padding: 0 30px;
      text-align: center;
      color: var(--white);

      @include sm {
        position: relative;
        z-index: 9999;
        padding: 0 15px;
      }

      .title {
        font-family: var(--font-roboto-condensed);
        font-size: 100px;
        font-style: italic;
        font-weight: 500;
        line-height: 100%; /* 100px */
        letter-spacing: -3px;
        margin-bottom: 7px;
        @include sm {
          font-size: 60px;
          font-style: italic;
          font-weight: 500;
          line-height: 120%; /* 72px */
          letter-spacing: -1.8px;
          div {
            font-size: 37px;
          }
        }
        .sub_title {
          @extend %big_header_txt;
        }
      }

      .description {
        @extend %big_txt;
      }
    }
  }
}
