@import "../../../../../../../shared/styles/media";
@import "../../../../../../../shared/styles/typography";

.info_cards_sub_section {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  @include lg {
    flex-direction: column;
  }

  .card {
    min-height: 517px;
    height: 70vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video_card {
    width: 60%;
    user-select: none;

    .content {
      position: relative;
      z-index: 2;
      color: var(--white);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 40px 40px 65px 40px;

      .accent_txt {
        color: var(--accent);
      }

      .title {
        @extend %header_1_txt;
      }

      .desc {
        font-family: var(--font-open-sans-condensed);
        font-size: 50px;
        font-style: italic;
        font-weight: 300;
        line-height: 120%; /* 60px */
        letter-spacing: -1.5px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 35.42%, rgba(0, 0, 0, 0) 70.83%, #000 100%);
    }

    .image {
      position: absolute;
      inset: 0;
      width: auto;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .play_button {
      position: absolute;
      width: 10%;
      height: auto;
      cursor: pointer;
      z-index: 5;
    }

    @include lg {
      width: 100%;
      height: 50vw;
      .content {
        text-align: center;
      }
    }

    @include md {
      width: 100%;
      height: 75vw;
      min-height: 300px;

      .content {
        padding: 20px;

        .desc {
          font-size: 40px;
          letter-spacing: -1px;
        }
      }
    }

    @include sm {
      .content {
        text-align: center;
        .title {
          font-size: 24px;
        }

        .desc {
          font-size: 20px;
          letter-spacing: -0.5px;
        }
      }
    }
  }

  .channel_card {
    position: relative;
    background: #000;
    width: 40%;
    overflow: hidden;

    .image {
      width: auto;
      height: 115%;
      object-fit: contain;
      position: absolute;
      z-index: 0;
      bottom: -35%;
      left: 0;
    }

    .content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      padding: 40px;
      color: var(--white);

      .heading {
        display: flex;
        flex-direction: column;
        gap: 44px;

        .title {
          position: relative;

          @extend %header_1_txt;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 52px;
            height: 5px;
            background: var(--accent);
          }
        }

        .desc {
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 30px */
          letter-spacing: -1px;
        }
      }

      .footer {
        display: flex;
        gap: 18px;
        align-items: center;
        width: 100%;
        .text {
          color: var(--accent);
          white-space: pre-wrap;

          @extend %header_2_txt;
        }

        .button {
          align-items: center;
          white-space: nowrap;
          .icon {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    @include xl {
      .content {
        padding: 20px;

        .footer {
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }

    @include lg {
      width: 100%;
      height: 50vw;

      .image {
        width: 130%;
      }
    }

    @include md {
      .image {
        width: 130%;
        min-width: 500px;
        height: auto;
      }
    }

    @include sm {
      .image {
        width: 150%;
        min-width: 700px;
        height: auto;
        bottom: -15%;
        left: -30%;
      }
    }
  }
}
