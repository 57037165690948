@import "shared/styles/typography.scss";

.side_menu_item_with_children {
}

.title {
  height: 40px;
  padding: 0;

  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;

  color: var(--ui-main-black-lite);
  @extend %paragraph_medium;
}

.items {
  padding-left: 15px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &:not(.open) {
    display: none;
  }
}
