@import "../../../../../../../shared/styles/media";
@import "../../../../../../../shared/styles/typography";

.heading_sub_section {
  display: flex;
  flex-direction: column;
  gap: 74px;
  padding: 120px 0 80px;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;

  .title {
    text-align: center;

    @extend %section_title_bottom_line;
  }

  @include sm {
    padding: 80px 0 0px;
    gap: 30px;

    .title {
      text-align: left;

      &::after {
        left: 0;
        transform: translate(0);
      }
    }
  }

  .info_list {
    display: flex;
    align-items: flex-start;
    gap: 30px;

    @include md {
      display: none;
    }

    .info_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      text-align: center;

      &_title {
        @extend %header_2_txt;
      }

      &_description {
        @extend %paragraph_16_reg;
      }
    }

    @include md {
      --gap: 20px;
      --row-length: 2;
      flex-wrap: wrap;

      gap: 30px 20px;
      .info_item {
        width: calc((100% - var(--gap) * (var(--row-length) - 1)) / var(--row-length));
      }
    }

    @include sm {
      --gap: 30px;
      --row-length: 1;
      flex-wrap: wrap;
      margin-top: 14px;

      gap: var(--gap);
      .info_item {
        align-items: flex-start;
        width: calc((100% - var(--gap) * (var(--row-length) - 1)) / var(--row-length));
        text-align: left;
      }
    }
  }
}
