@import "../../../../../../../shared/styles/media";
@import "../../../../../../../shared/styles/typography";

.search_box {
  display: flex;
  flex-direction: column;
  background: #f1f0f5;
  padding: 20px;
  width: 100%;
  gap: 21px;
  margin-bottom: 20px;

  .title {
    @extend %header_4_txt;
  }

  @include sm {
    padding: 20px 15px;
    margin-bottom: 0;
    position: absolute;
    z-index: 999;
    bottom: -100px;
    gap: 10px;
  }

  .search_input_group {
    display: flex;
    flex-direction: row;
    width: 100%;

    .input {
      outline: none !important;
      border-radius: 0;
    }

    .button {
      width: 30%;
      cursor: pointer;
    }

    @include sm {
      .button {
        display: none;
      }
    }
  }

  .brand_box {
    display: flex;
    flex-direction: column;
    gap: 9px;

    .label {
      color: var(--medium-gray);

      @extend %paragraph_16_med;
    }

    @include sm {
      display: none;
    }
  }

  .brand_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    .brand {
      display: flex;

      @extend %paragraph_14_med;
    }

    .all_brands {
      display: flex;
      text-decoration: underline;
      margin-left: 17px;
      color: var(--accent-dark);

      @extend %paragraph_16_med;
    }
  }
}

.searchListDesktop {
  width: 100%;
  top: 71px;
  max-width: 670px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #ffffff;
  button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    font-family: var(--font);
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    color: #000000;
    border-radius: 7px;
    cursor: pointer;
    transition: 350ms;
    background: none;
    border: none;
    span {
      font-weight: 700;
    }
    &:hover {
      background: #f2f2f2;
    }
    &[data-selected="true"] {
      background: #f2f2f2;
    }
    .main {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
}

.searchHistoryDesktop {
  h2 {
    padding: 14px 0 6px 13px;
    font-family: var(--font);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    color: #8b8b8b;
  }
}

.wrapper {
  position: relative;
  @include sm {
    display: none;
  }
}

.inputMobile {
  display: none;
  input {
    pointer-events: none;
  }
  @include sm {
    display: block;
  }
}
