@import "../../../../../shared/styles/media";
@import "../../../../../shared/styles/typography";

.catalog_popular {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 80px var(--container-horizontal-padding) 100px;
  gap: 60px;
  background: var(--white);

  @include sm {
    padding: 80px 10px 80px;
  }

  .heading {
    display: flex;
    flex-direction: column;
    gap: 44px;
    max-width: 942px;
    width: 100%;
    margin: 0 auto;

    @include sm {
      gap: 30px;
    }

    .title {
      @extend %section_title_bottom_line;
    }

    .description {
      color: var(--medium-gray);
      text-align: center;

      @extend %paragraph_16_reg;
    }
  }

  .products {
    --row-length: 5;
    --gap: 8px;

    width: 100%;
    max-width: 1164px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    .product_card {
      width: calc((100% - var(--gap) * (var(--row-length) - 1)) / var(--row-length));
      display: flex;
    }

    @include lg {
      --gap: 4px;
      --row-length: 3;
    }

    @include md {
      --row-length: 2;
      --gap: 4px;
    }

    @include sm {
      --row-length: 2;
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @include md {
      flex-wrap: wrap;
    }

    .link {
      @include md {
        width: 100%;
      }
      .button {
        text-transform: uppercase;
      }
    }
  }
}
