@import "../../../../../../../shared/styles/media";
@import "../../../../../../../shared/styles/typography";

.catalog_in_market_sub_section {
  display: flex;
  padding: 107px 0;
  flex-direction: column;
  align-items: center;
  gap: 41px;
  align-self: stretch;
  @include md {
    padding: 80px 0;
  }

  @include sm {
    padding: 65px 0 100px;
    gap: 40px;
  }

  .heading {
    display: flex;
    flex-direction: column;
    gap: 55px;
    max-width: 942px;

    .description {
      text-align: center;
      color: var(--medium-gray);
      @extend %paragraph_16_reg;
    }

    @include sm {
      gap: 40px;
      padding: 0 5px;
    }
  }

  .products {
    --row-length: 4;
    --gap: 20px;

    width: 100%;
    max-width: 1164px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    .product_card {
      width: calc((100% - var(--gap) * (var(--row-length) - 1)) / var(--row-length));
      display: flex;
    }

    @include md {
      --row-length: 3;
      --gap: 10px;
    }

    @include sm {
      --row-length: 2;
      --gap: 10px;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    .action {
      svg {
        width: 22px;
        height: 22px;
      }
    }

    @include sm {
      width: 100%;

      a {
        width: 100%;
        .action {
          width: 100%;
        }
      }
    }
  }
}
