@import "../../../../../../../shared/styles/media";
@import "../../../../../../../shared/styles/typography";

.info_video_sub_section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;

    .title {
      @extend %section_title_bottom_line;
    }

    .description {
      @extend %paragraph_16_reg;
    }
  }

  @include sm {
    gap: 40px;

    .heading {
      gap: 44px;
    }
  }

  .video_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .play_button {
      position: absolute;
      z-index: 3;
      cursor: pointer;
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 99999px;
      display: flex;
      align-items: center;
      justify-content: center;
      .play_icon {
        width: 100%;
        height: 100%;
      }
    }

    .image {
      width: 100%;
      height: auto;
    }
  }

  .button {
    align-items: center;
    border-color: currentColor;
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
