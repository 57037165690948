@import "../../../../../shared/styles/media";
@import "../../../../../shared/styles/typography";

.main_categories {
  --gap: 40px;
  --row-length: 3;

  display: flex;
  flex-direction: row;
  padding: 40px var(--container-horizontal-padding);
  gap: var(--gap);
  width: 100%;

  .category {
    width: calc((100% - var(--gap) * (var(--row-length) - 1)) / var(--row-length));
    background: #3e3e3e;
    position: relative;
    overflow: hidden;
    height: 75vh;
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .image {
        transition: all 0.3s linear;
        transform: scale(1.1);
      }
    }

    .image {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s linear;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      opacity: 0.6;
      background: rgba(0, 0, 0, 0.6);
    }

    .name {
      color: var(--white);
      text-align: center;
      position: relative;
      z-index: 2;

      @extend %header_1_txt;

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 52px;
        height: 5px;
        background: currentColor;
      }
    }
  }

  @include md {
    --gap: 15px;
    --row-length: 1;
    padding: 15px var(--container-horizontal-padding);
    flex-wrap: wrap;

    .category {
      width: 100%;
      height: 45vh;
      min-height: 75vw;
    }
  }
}
