@import "../../../../../shared/styles/media";
@import "../../../../../shared/styles/typography";

.split_section {
  padding: 110px var(--container-horizontal-padding);
  background: var(--poizon-gradient);
  color: var(--white);

  @include sm {
    padding: 100px var(--container-horizontal-padding);
  }

  .container {
    display: flex;
    gap: 30px;
    align-items: flex-start;
    width: 100%;
    max-width: var(--container-width);
    margin: 0 auto;

    .image {
      width: 50%;
      height: auto;
      display: flex;
    }

    .right_side {
      display: flex;
      flex-direction: column;
      width: 50%;
      gap: 50px;

      .heading {
        display: flex;
        flex-direction: column;
        gap: 34px;
        text-align: center;
        .title_box {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          width: 100%;
          text-align: center;

          .sub_title {
            @extend %paragraph_18_med;
          }

          .linear_text {
            z-index: 0;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            font-family: var(--font-open-sans-condensed);
            font-size: 240px;
            font-style: normal;
            font-weight: 700;
            line-height: 80%; /* 192px */
            letter-spacing: -7.2px;
            text-transform: uppercase;
          }

          .title {
            position: absolute;
            z-index: 1;
            bottom: -10px;

            font-family: var(--font-open-sans-condensed);
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 60px */
            letter-spacing: -1.5px;
            text-transform: uppercase;

            &::after {
              position: absolute;
              content: "";
              width: 52px;
              height: 5px;
              background-color: var(--white);
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      .content {
        display: flex;
        width: 100%;
        padding: 40px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        background-color: var(--background);
        text-align: left;

        .title {
          color: var(--black);
          font-family: var(--font-roboto-condensed);
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 42px */
          letter-spacing: -0.6px;
        }

        .description {
          color: var(--dark-gray);

          @extend %paragraph_14_reg;
        }

        .button {
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    @include md {
      flex-direction: column;
      gap: 60px;

      .image {
        margin: 0 auto;
        width: 75%;
      }

      .right_side {
        width: 100%;

        .content {
          padding: 30px;
        }
      }
    }

    @include sm {
      flex-direction: column;
      gap: 50px;

      .image {
        margin: 0 auto;
        width: 100%;
      }

      .right_side {
        width: 100%;

        .content {
          padding: 15px;
        }

        .heading {
          .title_box {
            .linear_text {
              font-size: 180px;
              font-style: normal;
              font-weight: 700;
              line-height: 80%; /* 144px */
              letter-spacing: -5.4px;
              text-transform: uppercase;
            }

            .title {
              font-size: 40px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 48px */
              letter-spacing: -1.2px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
