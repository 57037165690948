@import "../../../../../../../shared/styles/media";

.info_cards_sub_section {
  --gap: 30px;
  --row-length: 3;

  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap);

  .card {
    display: flex;
    max-width: 373px;
    width: calc((100% - var(--gap) * (var(--row-length) - 1)) / var(--row-length));
    height: 373px;
    flex-shrink: 0;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include md {
    --gap: 15px;
    --row-length: 1;
    flex-direction: column;

    .card {
      max-width: initial;
      height: auto;
    }
  }

  .guarantee_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 19px;

    .icon_box {
      display: flex;
      width: 242px;
      height: 242px;
      padding: 51px 52px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      border-radius: 9999px;
      background: var(--poizon-gradient);

      .icon {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 13px;

      .title {
        text-align: center;
        font-family: var(--font-roboto-condensed);
        font-size: 40px;
        font-style: italic;
        font-weight: 700;
        line-height: 100%; /* 40px */
        letter-spacing: -1.2px;
      }

      .description {
        color: var(--medium-gray);
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 30px */
        letter-spacing: -1px;
      }
    }
  }
}
